import React from 'react'
import {Flex, Box} from '@rebass/grid'
import format from 'lib/format'
import {Text, Button} from 'components'
import useCustomTranslation from 'lib/customT'
import TransWrapper from 'lib/transWrapper'
import { CopyToClipboardButton } from 'components'
import { useState } from 'react'

const LinksList = ({links, deactivate, domain}) => {
  const t = useCustomTranslation()
  const [lastCopied, setLastCopied] = useState("");

  setTimeout(() => {
    setLastCopied("")
  }, 4000);

  return (
    <Box style={{maxHeight: 300, overflowY: 'scroll'}}>
      <Flex justifyContent="space-between">
        <Box width={1 / 4}>
          <Text bold secondary>
            {t('Oprettet af')}
          </Text>
        </Box>
        <Box width={1 / 4}>
          <Text bold secondary >
            {t('Oprettelser')}
          </Text>
        </Box>
        <Box width={1 / 6} />
      </Flex>

      {links.map(({id, validTo, counter, createdBy, token}) => (
        <Flex justifyContent="space-between" my={2}>

          <Box width={1 / 4}>
            <Text secondary>
              {createdBy && `${createdBy.firstName} ${createdBy.surname}`}
            </Text>
          </Box>
          <Box width={1 / 4}>
            <Text secondary bold center>
              {counter}
            </Text>
          </Box>
          <Box width={1 / 6}>
            <CopyToClipboardButton 
              tiny
              block
              value={`https://${domain}/invitation?clubToken=${token}`}
              callback={() => setLastCopied(id)}
              >
                {lastCopied === id ? t('Kopieret!') : t('Kopier')}

              </CopyToClipboardButton>
          </Box>
          <Box width={1 / 6} >
            <Button tiny danger block onClick={() => deactivate(id)}>
              {t('Slet link')}
            </Button>
          </Box>
        </Flex>
      ))}
    </Box>
  )
}
export default LinksList
